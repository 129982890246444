import * as React from "react";
import { memo } from "react";
const SvgApolloLocation = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 25,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#29e9bda3-08a0-4a48-a966-53a47f4e74f4-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#F68A1F",
      strokeWidth: 2,
      d: "M20.86 10c0 5.18-7.16 12-8.18 12S4.5 15.18 4.5 10c0-4.42 3.66-8 8.18-8a8.1 8.1 0 0 1 8.18 8Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#F68A1F",
      d: "M12.8 6.37 13.65 9q.04.09.12.1h2.75c.12 0 .17.15.07.22l-2.22 1.62s-.06.1-.05.14l.85 2.62c.04.12-.1.21-.19.14l-2.22-1.62s-.1-.03-.15 0l-2.22 1.62c-.1.07-.23-.02-.19-.14l.85-2.62a.1.1 0 0 0-.05-.14L8.78 9.31c-.1-.07-.05-.23.07-.23h2.75q.08 0 .12-.09l.85-2.62c.04-.12.2-.12.24 0z"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "29e9bda3-08a0-4a48-a966-53a47f4e74f4-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M3.5 1h18.36v22H3.5z" })))
);
const Memo = memo(SvgApolloLocation);
export default Memo;
